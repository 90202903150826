.@{class-prefix}-page-title{
    margin : 0 32px
}
.@{class-prefix}-btn-page-title{
  align-items: center;
  justify-content: center;
  display: flex;
  height:100%;
  min-width: 40px
}

@media @lg {
    .@{class-prefix}-page-title{
      display: none;
    }

    .@{class-prefix}-topbar{
        display: flex !important;
    }
}

.@{class-prefix}-topbar{
    height: 56px;
    padding: 16px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: none;
  }
  
  .@{class-prefix}-topbar-title{
    font-size: 16px;
    margin-bottom: 0;
  }
  