.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: @primary-1;
}

.@{class-prefix}-card-contact{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 200px);
}

.@{class-prefix}-card-chat{
  height: calc(100vh - 200px);
}

.@{class-prefix}-chat-container{
  overflow-y: auto;
  overflow-x: hidden;
}

.@{class-prefix}-bubble-chat{
  display: inline-block;
}

.@{class-prefix}-dashboard-card{
  border: none;
  cursor: pointer;

  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    margin-right: 12px;
  }
  &:hover{
    .icon svg{
      transform: scale(1.1);
    }
  }
}

body.dark .ant-table-tbody > tr.ant-table-cell-row:hover > td,
body.dark .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: @dark-70;
}